import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactFormModal = ({ isOpen, onClose, handleSubmit }) => {



    const [submitMessage, setSubmitMessage] = useState("Request a Callback!"); // Initialize as true


    if (!isOpen) return null;

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setSubmitMessage("Sending...")
        const name = e.target.name.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value;






        emailjs
            .send('service_id', 'template_id', {
                name,
                email,
                phone,
            }, 'key')
            .then((response) => {
                toast.success("Email sent successfully!");
                onClose();
            })
            .catch((error) => {
                console.error('Email could not be sent:', error);
            });

    };

    const handleCloseButtonClick = () => {
        // Close the modal immediately without performing validation checks
        onClose();
    };


    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50 overflow-hidden">
            <ToastContainer position="top-center" autoClose={3000} />
            <div className="bg-white p-5 rounded-lg shadow-md relative md:w-[30%]">
                <button
                    className="absolute top-2 right-2 p-3 text-gray-600 hover:text-gray-800"
                    onClick={handleCloseButtonClick}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M1.293 1.293a1 1 0 011.414 0L8 6.586l5.293-5.293a1 1 0 111.414 1.414L9.414 8l5.293 5.293a1 1 0 01-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 01-1.414-1.414L6.586 8 1.293 2.707a1 1 0 010-1.414z"
                        />
                    </svg>

                </button>
                <form className="flex p-5 flex-col gap-4" onSubmit={handleFormSubmit}>
                    <h2 className="text-2xl font-semibold p-4 mt-2 font-kumbh text-center">Talk to our Specialist</h2>
                    <input
                        className="px-4 py-2 border rounded-md focus:outline-none focus:border-[#007cff]"
                        type="text"
                        placeholder="Name"
                        name='name'
                        required
                    />


                    <input
                        className="px-4 py-2 border rounded-md focus:outline-none focus:border-[#007cff]"
                        type="email"
                        placeholder="Email Address"
                        name='email'
                        required
                    />


                    <input
                        className="px-4 py-2 border rounded-md focus:outline-none focus:border-[#007cff]"
                        type="tel"  // Use type "tel" for phone numbers
                        placeholder="Phone Number"
                        inputMode="numeric"
                        pattern="[0-9]*"

                        name='phone'
                        required
                        onWheel={(e) => e.preventDefault()} // Disable scrolling with mouse wheel
                    />


                    <div className=" pt-10  flex justify-center items-center">
                        <button type='submit'
                            className="py-2 w-full md:w-[60%] px-4  bg-[#007cff] text-white rounded-full select-none selection:none hover:bg-transparent hover:text-[#007cff] border-2 border-[#007cff] duration-300 hover:scale-105"

                        >
                            {submitMessage}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactFormModal;




