import React from 'react'
import img from '../assets/portfolio.jpg'
import { Link } from 'react-router-dom';
const Portfolio = () => {


    const data = [
        {
            title: "Agency Landing Page 1",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa",
            img: img

        },
        {
            title: "Agency Landing Page 2",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa",
            img: img

        },
        {
            title: "Agency Landing Page 3",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa",
            img: img

        },
        {
            title: "Agency Landing Page 4",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa",
            img: img

        },
        {
            title: "Agency Landing Page 5",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa",
            img: img

        },
        {
            title: "Agency Landing Page 6",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa",
            img: img

        },

    ]


    return (
        <section className="font-kumbh mt-8 md:mt-0">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm">
                    <h2 className="mb-10 md:mb-14 text-4xl font-bold ">Portfolio</h2>
                </div>
                <div className="grid mb-8 lg:mb-12 lg:grid-cols-3 gap-8">
                    {data.map((item, index) => (
                        <figure key={index} className="flex flex-col justify-center items-start p-8 text-left border-b border-gray-200 md:p-12 lg:border-r hover:scale-105 duration-300 shadow-xl rounded-xl">
                            <img src={item.img} alt="img" className="h-60" />
                            <blockquote className="mb-8">
                                <h3 className="text-lg font-semibold mt-4">{item.title}</h3>
                                <p className="mt-4">"{item.desc}"</p>
                            </blockquote>
                            <figcaption className="flex justify-center items-center space-x-3">
                                <Link to="/ExtendedPortfolio" state={{ item }}


                                    className="py-2.5 text-white bg-black hover:text-black px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-200"
                                >
                                    View Case Study
                                </Link>
                            </figcaption>
                        </figure>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Portfolio