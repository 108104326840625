
import Main from './pages/Main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ExtendedTestimonials from './pages/ExtendedTestimonials';
import ExtendedPortfolio from './pages/ExtendedPortfolio';
import React, { useState, useEffect } from 'react';
import ContactFormModal from './components/ContactFormModal';
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);

  useEffect(() => {
    if (!hasModalBeenShown) {
      const handleScroll = () => {
        if (window.scrollY > window.innerHeight * 3) {
          setIsModalOpen(true);
          setHasModalBeenShown(true);
          window.removeEventListener('scroll', handleScroll);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [hasModalBeenShown]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />

          <Route path="/Extendedtestimonials" element={<ExtendedTestimonials />} />
          <Route path="/ExtendedPortfolio" element={<ExtendedPortfolio />} />
        </Routes>
      </BrowserRouter>
      <ContactFormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        handleSubmit={handleSubmit}
      />

    </>

  );
}

export default App;