import React from 'react'

import { Link } from 'react-router-dom';

const Testimonials = () => {


    const data = [
        {
            title: "Speechless Experience",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque tenetur excepturi nostrum tempore.",
            imgSrc: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
            name: "Bonnie Green",
            position: "Developer at Open AI",
        },
        {
            title: "Speechless Experience",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque tenetur excepturi nostrum tempore.",
            imgSrc: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
            name: "Bonnie Green",
            position: "Developer at Open AI",
        },
        {
            title: "Speechless Experience",
            desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque tenetur excepturi nostrum tempore.",
            imgSrc: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png",
            name: "Bonnie Green",
            position: "Developer at Open AI",
        },
    ]

    return (
        <section className="font-kumbh">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm">
                    <h2 className="mb-10 text-4xl  font-bold ">Testimonials</h2>
                    {/* <p className="mb-8  lg:mb-16 sm:text-xl">Explore the whole collection of open-source web components and elements built with the utility classNamees from Tailwind</p> */}
                </div>
                <div className="grid mb-8 lg:mb-12 lg:grid-cols-3 gap-8">


                    {data.map((item, index) => (
                        <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 lg:border-r hover:scale-105 duration-300 shadow-xl rounded-xl ">
                            <blockquote className="mx-auto mb-8 max-w-2xl">
                                <h3 className="text-lg font-semibold ">{item.title}</h3>
                                <p className="my-4">"{item.desc}"</p>

                            </blockquote>
                            <figcaption className="flex justify-center items-center space-x-3">
                                <img className="w-9 h-9 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png" alt="profile" />
                                <div className="space-y-0.5 font-medium  text-left">
                                    <div>{item.name}</div>
                                    <div className="text-sm font-light">{item.position}</div>
                                </div>
                            </figcaption>
                        </figure>
                    ))}




                </div>
                <div className="text-center ">
                    <Link
                        to="/Extendedtestimonials"
                        className="py-2.5   px-5 mr-2 mb-2 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-200"
                    >
                        Show more...
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Testimonials