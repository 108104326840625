import React from "react";
import Opolo from "../assets/Logos/001.png";
import Dixipay from "../assets/Logos/002.png";
import Flyeats from "../assets/Logos/003.png";
import Sosable from "../assets/Logos/004.png";
import Simpleaccounts from "../assets/Logos/005.png";
import Jempos from "../assets/Logos/007.png";
import ANR from "../assets/Logos/006.png";
import WSF from "../assets/Logos/008.png";
import HiroxS from "../assets/Logos/009.png";

const Clients = () => {
  return (
    <div name="about" className="w-full ">
      <section className="bg-white">
        <div className="py-2 lg:py-4 mx-auto max-w-screen-xl px-4">
          <h2 className="mb-8 lg:mb-16  xl:text-[2rem] font-bold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl">
            Trusted by clients across the globe
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 animate-pulse text-gray-500 sm:gap-12 dark:text-gray-400">
            <div className="flex justify-center items-center">
              <img
                src={Opolo}
                className="h-12 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={Dixipay}
                className="h-12 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={Flyeats}
                className="h-12 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>

            <div className="flex justify-center items-center">
              <img
                src={Sosable}
                className="h-12 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={Jempos}
                className="h-12 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={Simpleaccounts}
                className="h-12 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={WSF}
                className="h-20 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={ANR}
                className="h-20 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={HiroxS}
                className="h-20 hover:text-gray-900 dark:hover:text-black"
                alt="logo"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
