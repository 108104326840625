import React from "react";
import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaTwitch,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="w-full mt-24 bg-slate-50 py-8 px-2 overflow-x-hidden">
      <div className="max-w-[1240px] font-kumbh mx-auto grid grid-cols-2 md:grid-cols-4 border-b-2 border-gray-600 py-8">
        {/* <div>
                    <h6 className="font-bold  uppercase pt-2  ml-4">Solutions</h6>
                    <ul className="text-gray-700">
                        <li className="py-1">Marketing</li>
                        <li className="py-1">Analytics</li>
                        <li className="py-1">Commerce</li>
                        <li className="py-1">Data</li>
                        <li className="py-1">Cloud</li>
                    </ul>
                </div>
                <div>
                    <h6 className="font-bold uppercase pt-2 ml-4">Support</h6>
                    <ul className="text-gray-700">
                        <li className="py-1">Pricing</li>
                        <li className="py-1">Documentation</li>
                        <li className="py-1">Guides</li>
                        <li className="py-1">API Status</li>
                    </ul>
                </div>
                <div>
                    <h6 className="font-bold uppercase pt-2 ml-4">Company</h6>
                    <ul className="text-gray-700">
                        <li className="py-1">About</li>
                        <li className="py-1">Blog</li>
                        <li className="py-1">Jobs</li>
                        <li className="py-1">Press</li>
                        <li className="py-1">Partners</li>
                    </ul>
                </div>
                <div>
                    <h6 className="font-bold uppercase pt-2 ml-4">Legal</h6>
                    <ul className="text-gray-700">
                        <li className="py-1">Claims</li>
                        <li className="py-1">Privacy</li>
                        <li className="py-1">Terms</li>
                        <li className="py-1">Policies</li>
                        <li className="py-1">Conditions</li>
                    </ul>
                </div> */}
      </div>
      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">2023 Visionary Sols, All rights reserved</p>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
          <FaFacebook />
          <FaInstagram />
          <FaTwitter />
          <FaTwitch />
          <FaGithub />
        </div>
      </div>
    </div>
  );
};

export default Footer;
