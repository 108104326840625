import React, { useEffect } from "react";

const ExtendedTestimonials = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
    const timeout = setTimeout(() => {
      const container = document.querySelector('.extended-portfolio-container');
      if (container) {
        container.classList.add('active');
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <section className="bg-white  h-full w-full font-kumbh extended-portfolio-container">
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900  ">
            Testimonials
          </h2>
          <p className="mb-8 font-light text-gray-500 lg:mb-16 sm:text-xl  ">
            Explore the whole collection of open-source web components and
            elements built with the utility classes from Tailwind
          </p>
        </div>
        <div className="grid mb-8 lg:mb-12 lg:grid-cols-3 gap-8">
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col justify-center items-center p-8 text-center  border-b border-gray-200 md:p-12 hover:scale-105 duration-300 shadow-xl rounded-xl">
            <blockquote className="mx-auto mb-8 max-w-2xl ">
              <h3 className="text-lg font-medium ">Speechless Experience</h3>
              <p className="my-4 text-gray-700">
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Veritatis, ab. Officia sunt nulla aspernatur culpa, eaque
                tenetur excepturi nostrum tempore."
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center space-x-3">
              <img
                className="w-9 h-9 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="nothing"
              />
              <div className="space-y-0.5 font-medium text-left">
                <div>Roberta Casas</div>
                <div className="text-sm font-light ">
                  Lead designer at Dropbox
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default ExtendedTestimonials;
