import React from 'react'

const About = () => {
    return (
        <div name="about" className="w-full md:my-32">
            <div className="max-w-[900px] mr-2 md:mx-auto">
                <div className="text-center">
                    <h2 className=" text-[2.5rem] p-5 md:p-0 font-bold font-kumbh">
                        Everything you need to grow your restaurant business
                    </h2>
                    <p className="text-xl md:text-lg py-8 px-3 md:mx-10 md:py-6 text-gray-500 font-kumbh">
                        Comprehensive online ordering, customer data and profiling, integrated loyalty and retention campaigns. All with one goal: more revenue for your business
                    </p>
                </div>
                <div className="grid md:grid-cols-3 gap-4 md:px-2 px-5 mt-4 text-center">
                    <div className="border py-8 rounded-xl shadow-lg shadow-slate-500 hover:scale-105 duration-300">
                        <p className="text-[2.5rem] font-bold text-[#007cff] ">100%</p>
                        <p className="text-gray-400 mt-2">Completion</p>
                    </div>
                    <div className="border py-8 rounded-xl shadow-lg shadow-slate-500 hover:scale-105 duration-300">
                        <p className="text-[2.5rem] font-bold text-[#007cff]">24/7</p>
                        <p className="text-gray-400 mt-2">Delivery</p>
                    </div>
                    <div className="border py-8 rounded-xl shadow-lg shadow-slate-500 hover:scale-105 duration-300">
                        <p className="text-[2.5rem] font-bold text-[#007cff]">100K</p>
                        <p className="text-gray-400 mt-2">Transactions</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About