import React from "react";
import development from "../assets/Services/development.png";
import web from "../assets/Services/web-design.png";
import ecom from "../assets/Services/shopping-cart.png";
import blockchain from "../assets/Services/technology.png";
import game from "../assets/Services/game.png";
import graphic from "../assets/Services/graphic.png";
import seo from "../assets/Services/goal.png";
import smm from "../assets/Services/smm.png";

const Services = () => {
  return (
    <div name="platforms" className="w-full my-32 overflow-x-hidden h-">
      <div className="max-w-[1200px] mx-auto ">
        <h2 className="text-3xl p-5 xl:p-0 xl:max-w-full  text-center   xl:text-[2rem] font-bold  font-kumbh">
          Services We Offer
        </h2>

        <div className="flex  flex-col md:flex-row gap-4 pt-20 ">

          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={development}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>
          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={web}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>
          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={ecom}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>
          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={blockchain}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>




        </div>
        <div className="flex  flex-col md:flex-row gap-4 pt-20 ">

          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={game}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>
          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={graphic}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>
          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={seo}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>
          <div className="rounded-xl  border-slate-400 mx-auto max-w-[80%]   p-6   pb-20 min-w-[20%] hover:bg-[#007cff] hover:text-white scale-95  hover:scale-100 duration-300 my hover:border-0">
            <div className="flex justify-center  items-center flex-col gap-3 ">
              <img
                src={smm}
                alt="App Development"
                className="w-16 flex  fill-white text-white custom"
              />
              <div className="font-kumbh font-semibold">App Development</div>
              Crafting innovative mobile apps that bring your ideas to life.

            </div>
            <div className="font-kumbh text-md ">
              <ul
                className="list-disc pl-8 pt-5  text-gray-700 dark:text-gray-300 text-left">
                <li className="m-0 p-0">Android App</li>
                <li className="m-0 p-0">iOS App</li>
                <li className="m-0 p-0">Flutter</li>
                <li className="m-0 p-0">React Native</li>
                <li className="m-0 p-0">Kotlin</li>
                <li className="m-0 p-0">Ionic</li>
              </ul>
            </div>
          </div>




        </div>
      </div>
    </div>
  );
};

export default Services;
