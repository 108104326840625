import React from "react";
import img from "../assets/portfolio.jpg";

const Info = () => {
  return (
    <div name="platforms" className="w-full my-32 overflow-x-hidden">
      <div className="max-w-[1200px] mx-auto ">
        <div className="flex flex-col md:flex-row gap-2 items-center justify-center mt-14  p-5">
          {/* left  */}
          <div className="md:max-w-[50%] flex flex-col gap-8 pr-15 mb-8 ">
            <h1 className="font-kumbh text-3xl font-bold">
              Our team is ready to help you succeed in the long-term.
            </h1>
            <p className="font-kumbh ">
              At Visionary Sols, we are the architects of tomorrow's digital
              landscape. Founded on a vision of innovation, we've grown into a
              dynamic software house that pioneers groundbreaking solutions for
              businesses of all sizes. Our journey began with a passion for
              technology and a relentless pursuit of excellence. Today, we stand
              at the forefront of the industry, offering a comprehensive suite
              of services that include website development, SEO expertise,
              digital marketing strategies, blockchain solutions, and app
              development tailored to your unique needs.
            </p>
          </div>

          {/* right  */}
          <div className="w-[90vw] md:pl-6 mt-8 mr-3 md:mr-0  md:mt-0">
            <img src={img} alt="img" className="w-full" />
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row gap-2 items-center justify-center mt-14  p-5">
          {/* left  */}
          <div className="md:max-w-[50%] mt-10 md:mt-0 flex flex-col  pr-15">
            <img src={img} alt="img" className="w-full" />
          </div>

          {/* right  */}

          <div className="w-[90vw] md:pr-6 mt-8 mr-3 md:mr-0  md:mt-0  ">
            <div className="text  flex flex-col gap-1 pl-4">
              <h2 className="text-3xl font-bold mb-8">
                Why Choose Visionary Sols?
              </h2>
              <p className="text-lg ">
                <strong>Innovative Solutions:</strong> We embrace the latest
                technologies and trends to deliver solutions that give you a
                competitive edge.
              </p>
              <p className="text-lg ">
                <strong>Client-Centric Approach:</strong> Your success is our
                success. We prioritize your goals and collaborate closely to
                achieve them.
              </p>
              <p className="text-lg ">
                <strong>Experienced Team:</strong> Our team of experts brings a
                wealth of experience and expertise to every project.
              </p>
              <p className="text-lg ">
                <strong>Tailored Solutions:</strong> We don't believe in
                one-size-fits-all. Our solutions are customized to meet your
                unique needs.
              </p>
              <p className="text-lg">
                <strong>Proven Track Record:</strong> With a history of
                successful projects, you can trust us to deliver results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
