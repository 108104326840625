import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'


import logo from '../assets/logo.png'
import gallery from '../assets/gallery.png'
import gallery2 from '../assets/gallery2.png'
import Opolo from "../assets/Logos/001.png";
import Dixipay from "../assets/Logos/002.png";
import Flyeats from "../assets/Logos/003.png";
import Sosable from "../assets/Logos/004.png";

import Jempos from "../assets/Logos/007.png";


const ExtendedPortfolio = () => {
    const location = useLocation()
    const { item } = location.state
    useEffect(() => {

        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            const container = document.querySelector('.extended-portfolio-container');
            if (container) {
                container.classList.add('active');
            }
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className="w-full  my-10 min-h-[100vh]  overflow-x-hidden  font-kumbh extended-portfolio-container">
            <div className=" mx-auto">
                <div className='flex justify-center items-center flex-col gap-8'>
                    <img src={logo} alt='loggo' />
                    <h2 className="text-3xl p-5 xl:p-0 xl:max-w-full  text-center   xl:text-[2.3rem] font-bold  font-kumbh">{item.title}
                    </h2>
                    <p className='text-center text-lg leading-8 p-10'>Papa John's on-demand pizza delivery application allows various Papa John's franchises to deliver food at their customer's doorstep. It is an excellent app for drivers who wants to earn extra cash with their routine work. This app lets users register as a delivery boy, update their profile, see a current location and nearby stores, and order details. Using this app, drivers can also track the history of their delivered orders. This unique app enables drivers to view their job report details and let them change their service state.</p>
                    <img src={gallery} alt='galleryImg' />
                    <div className='flex'>
                        <div className='bg-indigo-600 hidden md:flex w-28'> {/* Use 'flex-1' for a flexible width */}
                            {/* Content for the first column */}
                        </div>
                        <div className='flex flex-col md:flex-row gap-2 flex-1'> {/* Use 'flex-1' for a flexible width */}
                            <div className='flex-1 flex justify-center text-left gap-5 items-start p-10 flex-col'> {/* Use 'flex-1' for a flexible width */}
                                <h1 className='text-5xl font-semibold '>Client Requirments</h1>
                                <p className='leading-7'>
                                    Papa John's on-demand pizza delivery application allows various Papa John's franchises to deliver food at their customer's doorstep. It is an excellent app for drivers who wants to earn extra cash with their routine work. This app lets users register as a delivery boy, update their profile, see a current location and nearby stores, and order details. Using this app, drivers can also track the history of their delivered orders. This unique app enables drivers to view their job report details and let them change their service state.
                                </p>

                            </div>
                            <div className='bg-indigo-600 flex-1 flex justify-center items-center p-5'> {/* Use 'flex-1' for a flexible width */}
                                <img src={gallery2} alt='gal2' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div name="about" className="w-full mt-20">
                <section className="bg-white">
                    <div className="py-2 lg:py-4 mx-auto max-w-screen-xl px-4">
                        <h2 className="  xl:text-[2rem] font-bold tracking-tight leading-tight text-center text-gray-900  md:text-4xl">
                            Trusted by clients across the globe
                        </h2>

                        <h2 className="mb-8 lg:mb-16  xl:text-[1rem]  tracking-tight leading-tight text-center text-gray-900  md:text-4xl">
                            We have used the latest technologies that can justify client requirements at the best to deliver bug-free solutions.
                        </h2>

                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 animate-pulse text-gray-500 sm:gap-12 dark:text-gray-400">
                            <div className="flex justify-center items-center">
                                <img
                                    src={Opolo}
                                    className="h-12 hover:text-gray-900 dark:hover:text-black"
                                    alt="logo"
                                />
                            </div>
                            <div className="flex justify-center items-center">
                                <img
                                    src={Opolo}
                                    className="h-12 hover:text-gray-900 dark:hover:text-black"
                                    alt="logo"
                                />
                            </div>
                            <div className="flex justify-center items-center">
                                <img
                                    src={Dixipay}
                                    className="h-12 hover:text-gray-900 dark:hover:text-black"
                                    alt="logo"
                                />
                            </div>
                            <div className="flex justify-center items-center">
                                <img
                                    src={Flyeats}
                                    className="h-12 hover:text-gray-900 dark:hover:text-black"
                                    alt="logo"
                                />
                            </div>

                            <div className="flex justify-center items-center">
                                <img
                                    src={Sosable}
                                    className="h-12 hover:text-gray-900 dark:hover:text-black"
                                    alt="logo"
                                />
                            </div>
                            <div className="flex justify-center items-center">
                                <img
                                    src={Jempos}
                                    className="h-12 hover:text-gray-900 dark:hover:text-black"
                                    alt="logo"
                                />
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
export default ExtendedPortfolio;